import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import "./styles.css";
import bbc from './utils/bbc.json';
import SCAMer from './assets/images/SCAMer.png';
import arrow from './assets/images/DownRedArrow.png';

function App() {
  const [mintAmount, setCount] = useState(() => { return 0; });
  const [currentAccount, setCurrentAccount] = useState("");
  const [VARIABLES, setCurrentGas] = useState({GAS: 0});

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have metamask!");
      return;
    } else {
      console.log("We have the ethereum obnject", ethereum);
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account:", account);
      setCurrentAccount(account);
    }
  }

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert("Get Metamask!");
        return;
      }

      const accounts = await ethereum.request({ method: "eth_requestAccounts" });

      console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const askContractToMintNft = async () => {
    const CONTRACT_ADDRESS = "";

    if (mintAmount === 0) {
      return;
    }

    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, bbc.abi, signer);

        let gasLimit = VARIABLES.GAS_LIMIT;

        let nftTxn = await connectedContract.mint(mintAmount, { gasLimit:gasLimit });
        console.log(nftTxn);

        console.log("Mining");
        await nftTxn.wait();
      } else {
        console.log("Ethereum object does not exist.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const setPrice = async () => {
    const variablesResponse = await fetch("/variables.json");
    let price = await variablesResponse.json();
    setCurrentGas(price);
  }

  // render connect button instead of mint button if not connected
  const renderNotConnectedContainer = () => (
    <div className="button-container">
      <button onClick={connectWallet} className="cta-button connect-wallet-button">
        Connect Wallet
      </button>
    </div>
  )

  const renderMintButton = () => (
    <div className="button-container">
      <div className="mint-amount-container">
        <button onClick={decrementCount} className="button count-button">-</button>
        <span className="count">{mintAmount}</span>
        <button onClick={incrementCount} className="button count-button">+</button>
      </div>
      <div className="mint-button-container">
        <button onClick={askContractToMintNft} className="button cta-button mint-button">
            CLAIM FREE BBC
        </button>
      </div>
    </div>
  )

  // reduce count on button
  function decrementCount() {
    if (mintAmount > 0) {
      setCount(prevCount => prevCount - 1);
    }
  }

  // increase count on button
  function incrementCount() {
    if (mintAmount < 20) {
      setCount(prevCount => prevCount + 1);
    }
  }

  useEffect(() => {
    setPrice();
  }, []);

  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <h1 className="header">SCAMERS</h1>
          <p className="crypto">
            BTC 30,561.97 <img className="arrow" src={ arrow } alt = "Red Arrow Down"/>
            ETH 1,786.12 <img className="arrow" src={ arrow } alt = "Red Arrow Down"/>
            SOL 41.26 <img className="arrow" src={ arrow } alt = "Red Arrow Down"/>
            BNB 289.55 <img className="arrow" src={ arrow } alt = "Red Arrow Down"/>
            MATIC 0.62 <img className="arrow" src={ arrow } alt = "Red Arrow Down"/>
            ADA 0.63 <img className="arrow" src={ arrow } alt = "Red Arrow Down"/>
            XRP 0.40 <img className="arrow" src={ arrow } alt = "Red Arrow Down"/>
            DOGE 0.08 <img className="arrow" src={ arrow } alt = "Red Arrow Down"/>
            SHIB 0.000011 <img className="arrow" src={ arrow } alt = "Red Arrow Down"/>
          </p>
          <div className="front-page">
            <div className="example">
              <img className="example-content" src={ SCAMer } alt = "SCAMer example"/>
            </div>
            <div className="front-page-content">
              <h1>CRYPTO IS CRASHING! BIG WIGS FROM WALL STREET BEGIN CLOSING IN!</h1>
              <p className="subline">Crypto tanks 32% in the past month. Will we ever see it recover?</p>
              <p className="description">
                SCAMers is a collection of Wall Street gents who are coming in to take your profits. The total collection is 4,444 SCAMers. The first 2,000 will be free, the remaining supply will be 0.0039 eth. SCAMers are hitting the blockchain on June 13th.
              </p>
              { currentAccount === "" ? renderNotConnectedContainer() : renderMintButton() }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;